import React from 'react';
import { styled } from '@mui/material/styles';
import { ListItem, ListItemButton, ListItemText, Box } from '@mui/material';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import { MediaProps } from 'sensortower-components/src/base-components/Media';
import { File, Asset } from 'sensortower-components/src/base-components/Media/Media.types';
import Link from '../Link';
import { sidekick } from '../../utils/sidekick';

export interface SubnavigationItem extends NavigationItemLinkProps {
  media: {
    __typename?: string;
    file?: File;
    fileTablet?: File;
    fileMobile?: File;
    variant?: string;
    title?: string;
    description?: string;
    desktop?: Asset;
    tablet?: Asset;
    mobile?: Asset;
    sidekickLookup?: string;
    sx?: any;
    testId?: string;
    priority?: boolean;
    disableInlineSVG?: boolean;
  };
}

export interface NavigationItemLinkProps {
  __typename?: string;
  id: string;
  variant?: 'group' | 'item' | 'link' | 'button' | 'login' | 'subgroup' | undefined;
  text: string;
  summary: string;
  media?: MediaProps & { __typename?: string };
  title?: string;
  href?: string;
  subNavigation?: SubnavigationItem[];
  sidekickLookup: any;
}

export const NavigationItemLink = ({ text, summary, href, sidekickLookup }: NavigationItemLinkProps) => {
  return (
    <ErrorBoundary>
      <ListItem {...sidekick(sidekickLookup)} data-testid="NavigationItemLink">
        <ListItemButton
          component={Link as any}
          href={href}
          noLinkStyle
          data-testid="NavigationItemLink-linkButton"
          sx={[
            {
              gap: 1,
              backgroundColor: 'transparent',
              '&:hover': {
                color: 'transparent'
              },
              // NOTE: fill only works with hex code
              '& svg': {
                fill: { xs: '#FFFFFF', lg: '#006b87' }
              },
              '& img[src$=".svg"]': {
                filter: { xs: '#FFFFFF', lg: 'invert(10%) brightness(80%) hue-rotate(12deg)' }
              }
            },
            (theme) => ({
              [theme.breakpoints.down('lg')]: {
                '&:hover': {
                  backgroundColor: theme.palette.grey[600]
                }
              }
            })
          ]}
        >
          {/* List style used in mobile version of the menu */}
          <Box alignItems="center" justifyContent="center" sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <Indent />
          </Box>

          {/* Hide summary text on small screens */}
          <ListItemText
            primary={text}
            secondary={summary}
            primaryTypographyProps={{ sx: {} }}
            secondaryTypographyProps={{ sx: { display: { xs: 'none', lg: 'block' } } }}
            data-testid="NavigationItemLink-linkText"
          />
        </ListItemButton>
      </ListItem>
    </ErrorBoundary>
  );
};

export default NavigationItemLink;

const Indent = styled(Box, {
  name: 'NavigationItemLink',
  slot: 'MobileIndent'
})<{ variant?: string }>(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  width: 10
}));
